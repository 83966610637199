body {
  color: var(--bs-dark-text);
  overflow-y: scroll; /* Force the viewport scrollbar to be on all the time */
}

a:link {
  text-decoration: none !important;
}

.outlet {
  min-height: 400px;
}

.row-striped:nth-child(odd) {
  background-color: var(--bs-gray-100);
}

.row-striped:nth-child(even) {
  background-color: var(--bs-gray-400);
}

/************************************************************
 Price Badges
 ************************************************************/
.badge {
  background-color: white;
}

/************************************************************
 Breadcrumbs
 ************************************************************/
.breadcrumb {
  font-size: 0.75rem;
  margin-bottom: 0rem;
}

/************************************************************
 Product
 ************************************************************/
.product-title {
  overflow-wrap: break-word;
}

.product-price {
  font-size: 2rem;
  color: green;
}

.product-price-note {
  font-size: 0.75rem;
}

#variantsContainer {
  --bs-accordion-btn-bg: unset;
  --bs-accordion-active-bg: unset;
  --bs-accordion-border-color: unset;
  --bs-accordion-border-width: unset;
  --bs-accordion-active-color: unset;
  --bs-accordion-btn-focus-box-shadow: unset;
}

#variantsHeader {
  border-bottom: none;
}

#variantsBody.show,
#variantsBody.collapsing {
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

#variantsAccordionButton {
  padding: unset;
}

/************************************************************
 Product Image
 ************************************************************/
.product-image-container {
  height: 200px;
}

.product-image-container-thumbnail {
  height: 150px;
}

.product-image {
  max-height: 200px;
}

.product-image-thumbnail {
  max-height: 150px;
}

/************************************************************
 Product List Toolbar
 ************************************************************/
.product-list-toolbar-btn {
  font-size: 1rem;
  border: var(--bs-border-width) solid var(--bs-gray-500);
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

/************************************************************
 Product Grid
 ************************************************************/
.product-grid-card-img {
  position: relative;
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
}

.product-grid-badge-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: inline;
    z-index: 1000;
}

.product-grid-card-title {
  height: 3.75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  overflow: hidden;
}

.product-grid-button-container {
  background-color: transparent;
}

.product-grid-curr-price {
  font-size: 1.5rem;
}

.product-grid-prev-price {
  font-size: 0.75rem;
}

.product-grid-save {
  font-size: 0.75rem;
}

.product-grid-green {
  color: green;
}

/************************************************************
 Navbar
 ************************************************************/
.navbar-toggler {
  font-size: 1.15rem;
}

.navbar-element,
.navbar-element:focus,
.navbar-element:hover,
.navbar-element:hover:active,
.navbar-element:active:focus {
  box-shadow: none !important;
  border: var(--bs-border-width) solid var(--bs-gray-500);
}

.navbar-button:hover {
  background-color: var(--bs-gray-300);
}

/************************************************************
 Navbar search input - icon/loading animation
 ************************************************************/
/*

.loading {
  opacity: 0.25;
  background-color: red;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

nav div form {
  position: relative;
}

nav div form div input[type="search"] {
  width: 100%;
  padding-left: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23999' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0.625rem 0.75rem;
  background-size: 1rem;
  position: relative;
}

nav div form div input[type="search"].loading {
  background-image: none;
}

#search-spinner {
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9' /%3E%3C/svg%3E");
  animation: spin 1s infinite linear;
  position: absolute;
  left: 0.625rem;
  top: 0.75rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/************************************************************
 Home
 ************************************************************/
.home-promo-icon {
  font-size: 4rem;
}